import React from 'react';
import { ParallaxBanner, ParallaxProvider } from 'react-scroll-parallax';
import { Col, Container, Row } from 'react-bootstrap';
import SEO from 'components/seo';
import VideoYouTube from 'components/video';
import Herotech8Banner from 'components/herotech8Banner';
import useWindowSize from 'hooks/useWindowSize';


function DroneInABox() {
  const size = useWindowSize();
  const videoClassBasedOnHeight = size.height > size.width ? 'h-100' : 'w-100';
  return (
    <ParallaxProvider>
      <SEO title="HEROTECH8 - Autonomous Drone in a Box, Greece, Cyprus, Balkans" />

      <section className="dib-header d-flex flex-column justify-content-center align-items-center bg-black">
        <div className="mw-100 mh-100" style={{ height: size.height > size.width ? 'calc(100vh - 80px)' : 'auto' }}>
          <video
            autoPlay playsInline muted loop
            className={videoClassBasedOnHeight}
          >
            <source src="/assets/what-we-do/drone-in-a-box/ht8-videobg_v2.mp4" type="video/mp4" />
            <img src="/assets/what-we-do/drone-in-a-box/video.jpg" title=" " alt=" " />
          </video>
        </div>

        <div
          className="position-absolute w-100 d-flex align-items-center justify-content-center py-5 py-md-0 order-1 order-md-2"
        >
          <div className="text-center rounded-circle py-7 px-8 border border-info" style={{ backgroundColor: 'rgba(255, 255, 255, 0.9)', maxWidth: 480 }}>
            <img src="/assets/what-we-do/drone-in-a-box/mavic-header.png" className="img-fluid" alt=" " />
            <h2 style={{ textDecoration: 'underline', textDecorationColor: 'var(--info)', textUnderlineOffset: '0.5rem' }}>DRONE IN A BOX</h2>
            <p>FULLY AUTOMATED DRONE SERVICES, REMOTELY ACCESSED FROM ANYWHERE IN THE WORLD</p>
          </div>
        </div>
      </section>

      <div className="bg-black">
        <Container fluid className="py-6">
          <Row>
            <Col>
              <Herotech8Banner h1="Emvelia Technologies" />
            </Col>
          </Row>
        </Container>
      </div>

      <section>
        <div className="position-relative bg-black">
          <ParallaxBanner
            className=""
            style={{ aspectRatio: '16 / 9' }}
            layers={[
              {
                speed: -15,
                children: (
                  <img src="/assets/what-we-do/drone-in-a-box/mavic3.jpg" className="img-fluid" title=" " alt=" " />
                ),
              },
            ]}
          />
        </div>
      </section>


      <section>
        <div className="position-relative bg-black" style={{ background: 'transparent url(\'/assets/what-we-do/drone-in-a-box/bg.png\') no-repeat center/cover' }}>
          <ParallaxBanner
            className=""
            layers={[
              {
                speed: -50,
                image: '/assets/what-we-do/drone-in-a-box/bg.png',
              },
            ]}
          >
            <Container fluid className="py-8 text-white">
              <Row className="mb-4 py-4">
                <Col sm={12} md={6} className="mb-4 mb-md-0">
                  <img src="/assets/what-we-do/drone-in-a-box/security.png" className="img-fluid img-thumbnail" alt=" " style={{ boxShadow: '0 4px 4px 0 rgba(0,0,0,.4)' }} />
                </Col>
                <Col sm={12} md={6}>
                  <h2 className="font-weight-bolder underscore">Security & Surveillance</h2>
                  <ul>
                    <li><h4>Automated Perimeter Patrolling</h4></li>
                    <li><h4>Automated Alarm Response</h4></li>
                    <li><h4>Site Monitoring</h4></li>
                    <li><h4>Emergency Aerial Response</h4></li>
                  </ul>
                </Col>
              </Row>

              <Row className="mb-4 py-4">
                <Col sm={12} md={6}>
                  <h2 className="font-weight-bolder underscore">Facility & Asset Management</h2>
                  <ul>
                    <li><h4>Asset inspection</h4></li>
                    <li><h4>Facility & Rooftop Inspection</h4></li>
                    <li><h4>Area surveying</h4></li>
                    <li><h4>Project Progress Monitoring</h4></li>
                  </ul>
                </Col>
                <Col sm={12} md={6} className="mt-4 mt-md-0">
                  <img src="/assets/what-we-do/drone-in-a-box/asset-inspection.png" className="img-fluid img-thumbnail" alt=" " style={{ boxShadow: '0 4px 4px 0 rgba(0,0,0,.4)' }} />
                </Col>
              </Row>

              <Row className="mb-4 py-4">
                <Col sm={12} md={6} className="mt-4 mt-md-0">
                  <img src="/assets/what-we-do/drone-in-a-box/sectors.png" className="img-fluid" alt=" " />
                </Col>

                <Col sm={12} md={6}>
                  <h2 className="font-weight-bolder underscore">Sectors</h2>
                  <ul>
                    <li><h4>Construction & The Built Environment</h4></li>
                    <li><h4>Utilities, Infrastructure & Transportation</h4></li>
                    <li><h4>Emergency Services</h4></li>
                    <li><h4>Facilities Management</h4></li>
                    <li><h4>Security & Defence</h4></li>
                  </ul>
                </Col>
              </Row>

              <Row className="justify-content-center mt-7 py-8 text-center">
                <Col sm={12} md={8}>
                  <h3>DEMONSTRATION</h3>
                  <div className="img-thumbnail" style={{ boxShadow: '0 4px 4px 0 rgba(0,0,0,.4)' }}>
                    <VideoYouTube url="https://www.youtube.com/embed/NrEnjoC7gik" />
                  </div>
                </Col>
              </Row>

              <Row className="justify-content-center py-8 text-center">
                <Col sm={12} md={6}>
                  <h1 style={{ textDecoration: 'underline', textDecorationColor: 'var(--info)', textUnderlineOffset: '0.5rem' }}>Flexible Plans</h1>
                  <h3 className="mb-0">Direct Purchase</h3>
                  <div className="my-4 small">OR</div>
                  <h3 className="mb-0">Leasing</h3>

                  <a className="btn btn-outline-info font-weight-normal mt-5" href="/assets/Emvelia Technologies - HeroTech8 - Brochure.pdf" download="Emvelia Technologies - HeroTech8 - Brochure.pdf">
                    Download Brochure
                    <small className="d-block text-white">View Specs</small>
                  </a>
                </Col>
              </Row>
            </Container>
          </ParallaxBanner>
        </div>
      </section>
    </ParallaxProvider>
  );
}

export default DroneInABox;
