import { Col, Container, Row } from 'react-bootstrap';
import React from 'react';
import { Link } from 'gatsby';

const Herotech8Banner = ({ h1 = 'DroneTech', action = 'brochure' }) => (
  <div
    className="bg-black text-white py-4 py-xl-7 bg-down-md-img-none herotech8-banner"
    style={{ background: '#000 url("/assets/what-we-do/drone-in-a-box/247-intelligence.jpg") no-repeat center right/contain' }}
  >
    <Container fluid>
      <Row>
        <Col lg={6}>
          <h1 className="font-weight-bold underscore">{h1}</h1>
          <h2 className="text-info">Official Hellenic Representative for HEROTECH8, Drone-In-A-Box Technology</h2>
          <p>Bringing to Greece, Cyprus and the Balkans, the best British drone technology for Autonomous Inspection in Construction, Surveillance with Computer Vision and Unmanned Medical Equipment Transfer.</p>

          {action === 'brochure' &&
            <a className="btn btn-outline-info mt-5" href="/assets/Emvelia Technologies - HeroTech8 - Brochure.pdf" download="Emvelia Technologies - HeroTech8 - Brochure.pdf">Download Brochure</a> ||
            <Link to="/what-we-do/drone-in-a-box" className="btn btn-outline-light mt-5">Find out more</Link>}
        </Col>
        <Col xs={12} className="d-lg-none text-center">
          <img src="/assets/what-we-do/drone-in-a-box/247-intelligence.jpg" className="img-fluid" alt=" " />
        </Col>
      </Row>
    </Container>
  </div>
);

export default Herotech8Banner;
