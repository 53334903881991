import React from 'react';
import PropTypes from 'prop-types';


const VideoYouTube = ({ url, ...props }) => {
  const finalUrl = new URL(url);
  finalUrl.searchParams.append('modestbranding', 1);

  return (
    <div
      style={{
        position: 'relative',
        paddingBottom: 'calc(var(--aspect-ratio, .5625) * 100%)', // 16:9
        height: 0,
      }}
    >
      <iframe
        src={finalUrl.href}
        frameBorder="0"
        loading="lazy"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
        }}
        {...props}
      />
    </div>
  );
};

VideoYouTube.propTypes = {
  url: PropTypes.string.isRequired,
};

export default VideoYouTube;
